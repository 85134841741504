import "../App.css"

const basicStyle = {
	customClass: {
		container: "sweetalert",
	},
	// confirmButtonColor: MAINCOLOR,
	denyButtonColor: "silver",
}

// -------------------------------- SweetAlert2 Error Dialog Box

// Generic Messages

export const swalError = (msg) => ({
	...basicStyle,
	title: "Error",
	html: msg,
	focusConfirm: true,
})

export const deleteImageWarning = {
	...basicStyle,
	title: "This action is irreversible!",
	html: "Remove Image Listing?",
	showDenyButton: true,
	showCancelButton: false,
	confirmButtonText: "Yes",
	denyButtonText: "No",
}

export const imageRenumbering = {
	...basicStyle,
	html: "Swap or insert image in this position?",
	showDenyButton: true,
	showCancelButton: true,
	confirmButtonText: "Swap",
	denyButtonText: "Insert",
}
