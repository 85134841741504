import { PropTypes } from "prop-types"

import { Button, Tooltip } from "@material-ui/core"

import { BsFillGridFill, BsListUl } from "react-icons/bs"

const Toolbar = (props) => {
	return (
		<div style={styles.mainContainer}>
			<div style={styles.body}>
				<div style={styles.emptyDiv} />
				<div style={styles.pagetitle}>Admin Panel</div>
				<div style={styles.tools}>
					<Tooltip title="Edit Details">
						<Button style={styles.iconButton} onClick={() => props.onChangeView(0)}>
							<BsListUl />
						</Button>
					</Tooltip>
					<Tooltip title="Reorder">
						<Button style={styles.iconButton} onClick={() => props.onChangeView(1)}>
							<BsFillGridFill />
						</Button>
					</Tooltip>
					<Button onClick={() => props.onAddNewImage()}>Add New Image</Button>
				</div>
			</div>
		</div>
	)
}

Toolbar.propTypes = {
	onAddNewImage: PropTypes.func,
	onChangeView: PropTypes.func,
}

const styles = {
	mainContainer: {
		top: "10vh",
		height: "5vh",
		position: "fixed",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		zIndex: 1000,
		backgroundColor: "white",
	},
	body: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		width: "98%", //
	},
	emptyDiv: {
		display: "flex",
		flex: 1, //
	},
	pagetitle: {
		color: "gray",
		letterSpacing: "0.4em",
		flex: 4,
		justifyContent: "center",
		display: "flex", //
	},
	tools: {
		flex: 1,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-end", //
	},
	iconButton: {
		height: "35px", //
	},
}
export default Toolbar
