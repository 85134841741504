import "../App.css"

import babyphoto from "../images/babyphoto.jpeg"

const EasterEgg = () => {
	return (
		<div style={styles.mainContainer} className="body">
			<div className="easterEggCard">
				<div className="cardImage">
					<img src={babyphoto} alt="Baby" style={{ height: "100%" }} />
				</div>
				<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
					<div style={styles.title}>HAPPY BIRTHDAY BABY!</div>
					<div style={styles.textContainer}>
						You are the most beautiful, both in looks and character, and the most passionate, selfless and compassionate person I had ever met.
						<p>I am really so much in love with you. I really am.</p>
						<p>
							Added: I am really so sorry baby for missing the day of your birthday! I didn't mean to, and I had stupidly having this thought in my
							head that it was gonna be today that is 10th Feb in Pennsylvania. I don't know why I had been so stupid. I will spend my life making it
							up to you.
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

const styles = {
	mainContainer: {
		height: "70%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	title: {
		marginTop: "20px",
		marginBottom: "10px",
		textAlign: "center",
		fontSize: "12px",
		fontWeight: 'bold'
	},
	textContainer: {
			textAlign: "center",
		fontSize: "12px",
	},
}

export default EasterEgg
