import "./App.css"

import { createTheme, MuiThemeProvider } from "@material-ui/core"

import LandingPage from "./components/LandingPage"

export const THEME = createTheme({
	typography: {
		fontFamily: `"Quicksand", sans-serif`,
	},
})

function App() {
	return (
		<MuiThemeProvider theme={THEME}>
			<div className="App">
				<LandingPage />
			</div>
		</MuiThemeProvider>
	)
}

export default App
