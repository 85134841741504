import CryptoJS from "crypto-js"
const SERVER = "https://www.nataliespringfield.com:3000"

const _send = (module) => {
	return new Promise((resolve, reject) => {
		const data = {
			method: module.method || "POST", // defaults to POST if not explicitly stated
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(module.data) || null,
		}

		fetch(SERVER + "/" + module.module, data)
			.then((res) => res.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error))
	})
}

export const auth = (username, password) => {
	const credentials = JSON.stringify({ username, password })
	const encrypted = CryptoJS.AES.encrypt(credentials, "534m0r3&Wr3n").toString()

	return new Promise((resolve, reject) => {
		_send({
			module: "auth",
			data: { credentials: encrypted },
		})
			.then((result) => {
				result.status === "OK" ? resolve(result.msg) : reject(result.msg) // returns image list upon successful login
			})
			.catch((error) => reject(error))
	})
}

export const saveImage = (data) => {
	return new Promise((resolve, reject) => {
		_send({
			module: "saveImage",
			data: { ...data },
		})
			.then((result) => {
				result.status === "OK" ? resolve(result.msg) : reject(result.msg)
			})
			.catch((error) => reject(error))
	})
}

export const updateImage = (data) => {
	return new Promise((resolve, reject) => {
		_send({
			module: "updateImage",
			data: { ...data },
		})
			.then((result) => {
				result.status === "OK" ? resolve(result.msg) : reject(result.msg)
			})
			.catch((error) => reject(error))
	})
}

export const deleteImage = (_id) => {
	return new Promise((resolve, reject) => {
		_send({
			module: "deleteImage",
			data: { _id },
		})
			.then((result) => {
				result.status === "OK" ? resolve(result.msg) : reject(result.msg)
			})
			.catch((error) => reject(error))
	})
}

export const getImageList = (onlyActive) => {
	return new Promise((resolve, reject) => {
		_send({
			module: "getImageList",
			data: { onlyActive },
		})
			.then((result) => {
				result.status === "OK" ? resolve(result.msg) : reject(result.msg)
			})
			.catch((error) => reject(error))
	})
}

export const reorder = (ids) => {
	return new Promise((resolve, reject) => {
		_send({
			module: "reorder",
			data: { ids },
		})
			.then((result) => {
				result.status === "OK" ? resolve(result.msg) : reject(result.msg)
			})
			.catch((error) => reject(error))
	})
}
