import { useRef, useState } from "react"
import { PropTypes } from "prop-types"

import { Button, IconButton, MenuItem, Modal, MuiThemeProvider, TextField, Typography } from "@material-ui/core"

import { IoCloseOutline } from "react-icons/io5"

import { deleteImage, reorder, saveImage, updateImage } from "../../common/network"

import Toolbar from "./Toolbar"
import ImageDetails from "./ImageDetails"

import { THEME } from "../../App"
import Swal from "sweetalert2"
import { deleteImageWarning, imageRenumbering, swalError } from "../../common/sweetalert"

import Spinner from "../Spinner"

const IMAGESERVER = "https://www.nataliespringfield.com/images/"

const DetailedViewRow = (props) => {
	const [active, setActive] = useState(props.data.active)
	const [title, setTitle] = useState(props.data.title)
	const [category, setCategory] = useState(props.data.category)
	const [nftLink, setNFTLink] = useState(props.data.nftLink)
	const [description, setDescription] = useState(props.data.description)

	const [imageWidth, setImageWidth] = useState(props.data.width)
	const [imageHeight, setImageHeight] = useState(props.data.height)
	const [unitOfMeasurement, setUnitOfMeasurementt] = useState(props.data.unitOfMeasurement)

	const descriptionRef = useRef()

	const modified = () => {
		return (
			active !== props.data.active ||
			title !== props.data.title ||
			category !== props.data.category ||
			nftLink !== props.data.nftLink ||
			description !== props.data.description ||
			imageWidth !== props.data.width ||
			imageHeight !== props.data.height ||
			unitOfMeasurement !== props.data.unitOfMeasurement
		)
	}

	const resetFields = () => {
		setActive(props.data.active)
		setTitle(props.data.title)
		setCategory(props.data.category)
		setNFTLink(props.data.nftLink)
		setDescription(props.data.description)
	}

	const filename = IMAGESERVER + props.data._id + ".png"

	const nonModifiedShadow = "inset 1px 1px 10px silver"
	const modifiedShadow = "inset 1px 1px 10px red"

	const changeMeasurementUnit = (e) => {
		if (e.target.value === "in") {
			// changing cm to inches
			imageHeight > 0 && setImageHeight(parseFloat((imageHeight / 2.54).toFixed(2)).toString())
			imageWidth > 0 && setImageWidth(parseFloat((imageWidth / 2.54).toFixed(2)).toString())
		} else {
			// changing inches to cm
			imageHeight > 0 && setImageHeight(parseFloat((imageHeight * 2.54).toFixed(2)).toString())
			imageWidth > 0 && setImageWidth(parseFloat((imageWidth * 2.54).toFixed(2)).toString())
		}

		setUnitOfMeasurementt(e.target.value)
	}
	return (
		<MuiThemeProvider theme={THEME}>
			<div style={{ ...styles.listContainer, boxShadow: modified() ? modifiedShadow : nonModifiedShadow }}>
				<div style={styles.imageContainer}>
					<img src={filename} alt={"selected"} style={{ height: "180px" }} />
				</div>
				<div style={styles.details}>
					<div style={{ margin: "20px" }}>
						<div style={{ marginBottom: "10px", display: "flex", flexDirection: "row" }}>
							<TextField
								value={active}
								onChange={(e) => setActive(e.target.value)} // Active
								select
								label="Active"
								InputLabelProps={{ shrink: true }}
								style={{ width: "200px" }}
							>
								<MenuItem value={true}>Yes</MenuItem>
								<MenuItem value={false}>No</MenuItem>
							</TextField>

							<TextField
								value={title}
								label="Title"
								onChange={(e) => setTitle(e.target.value)} // Title
								InputLabelProps={{ shrink: true }}
								style={{ marginLeft: "10px", width: "50%" }}
							/>

							<TextField
								value={imageWidth}
								label="Width"
								type={"number"}
								onChange={(e) => setImageWidth(e.target.value)} // Width
								InputLabelProps={{ shrink: true }}
								InputProps={{ inputProps: { min: 0 } }}
								style={{ marginLeft: "10px", width: "80px" }}
							/>

							<div style={{ display: "flex", alignItems: "flex-end", marginRight: "20px" }}>
								<TextField select value={unitOfMeasurement} onChange={changeMeasurementUnit}>
									<MenuItem value="cm">cm</MenuItem>
									<MenuItem value="in">in</MenuItem>
								</TextField>
							</div>

							<div style={{ display: "flex", alignItems: "flex-end", marginRight: "20px" }}>
								<Typography>x</Typography>
							</div>

							<TextField
								value={imageHeight}
								label="Height"
								type={"number"}
								onChange={(e) => setImageHeight(e.target.value)} // Height
								InputLabelProps={{ shrink: true }}
								InputProps={{ inputProps: { min: 0 } }}
								style={{ marginLeft: "10px", width: "80px" }}
							/>
							<div style={{ display: "flex", alignItems: "flex-end", marginRight: "20px" }}>
								<TextField select value={unitOfMeasurement} onChange={changeMeasurementUnit}>
									<MenuItem value="cm">cm</MenuItem>
									<MenuItem value="in">in</MenuItem>
								</TextField>
							</div>
						</div>
						<div style={{ marginBottom: "10px" }}>
							<TextField
								value={category}
								onChange={(e) => setCategory(e.target.value)} // Category
								label="Category"
								InputLabelProps={{ shrink: true }}
								style={{ width: "200px" }}
							/>

							<TextField
								value={nftLink}
								onChange={(e) => setNFTLink(e.target.value)} // NFT link
								label="NFT Link"
								InputLabelProps={{ shrink: true }}
								style={{ marginLeft: "10px", width: "50%" }}
							/>
						</div>

						<div
							style={{ height: "100%", border: "1px solid silver", borderRadius: "5px", padding: "10px" }}
							onClick={() => descriptionRef.current.focus()}
						>
							<TextField
								label="Description"
								value={description}
								onChange={(e) => setDescription(e.target.value)} // Description
								multiline
								inputRef={descriptionRef}
								maxRows={4}
								style={{ width: "100%" }}
								InputLabelProps={{ shrink: true }}
								InputProps={{ disableUnderline: true }}
							/>
						</div>
					</div>
				</div>
				<div style={{ display: "flex", flex: 1, position: "relative" }}>
					<div style={{ top: "10px", right: "10px", position: "absolute" }}>
						<IconButton
							onClick={() =>
								Swal.fire(deleteImageWarning).then((result) => {
									if (result.isConfirmed) {
										deleteImage(props.data._id)
											.then((res) => props.refreshlist(res))
											.catch((error) => Swal.fire(swalError(error)))
									}
								})
							}
						>
							<IoCloseOutline />
						</IconButton>
					</div>
					<div style={styles.buttons}>
						<Button disabled={!modified()} onClick={resetFields}>
							Reset
						</Button>
						<Button
							disabled={!modified()}
							variant="outlined"
							color="secondary"
							onClick={() => {
								setImageHeight(parseFloat(imageHeight).toString())
								setImageWidth(parseFloat(imageWidth).toString())

								if ((imageHeight === "0" && imageWidth !== "0") || (imageWidth === "0" && imageHeight !== "0"))
									Swal.fire(swalError("Invalid Height/Width combination"))
								else
									updateImage({
										_id: props.data._id,
										active,
										title,
										category,
										nftLink,
										description,
										height: parseFloat(imageHeight).toString(), // redo parseFloat/toString as setState might be batched/delayed
										width: parseFloat(imageWidth).toString(),
										unitOfMeasurement,
									})
										.then((res) => props.refreshlist(res))
										.catch((error) => Swal.fire(swalError(error)))
							}}
						>
							Update
						</Button>
					</div>
				</div>
			</div>
		</MuiThemeProvider>
	)
}

DetailedViewRow.propTypes = {
	data: PropTypes.object,
	refreshlist: PropTypes.func,
}

const DetailedView = (props) => {
	return (
		<div style={styles.imagelist}>
			<div>
				{props.imagelist.map((i) => {
					return <DetailedViewRow key={i._id} data={i} refreshlist={(res) => props.refreshlist(res)} />
				})}
			</div>
		</div>
	)
}

DetailedView.propTypes = {
	imagelist: PropTypes.array,
	refreshlist: PropTypes.func,
}

const GridView = (props) => {
	const replace_dragover = (e) => {
		e.preventDefault()
		e.currentTarget.className = "replaceDiv replaceDivHover" // change in classnames to create border for target div during drop
	}

	const replace_dragleave = (e) => {
		e.preventDefault()
		e.currentTarget.className = "replaceDiv"
	}

	function replace_drop(e) {
		e.preventDefault()
		e.currentTarget.className = "replaceDiv"
		const list = [...props.imagelist]

		const a = parseInt(e.currentTarget.firstElementChild.id) // get target image
		const b = parseInt(e.dataTransfer.getData("src")) // get source image

		a !== b &&
			Swal.fire(imageRenumbering).then((result) => {
				if (result.isConfirmed) {
					// swap
					;[list[a], list[b]] = [list[b], list[a]]
					props.refreshlist(list)
					reorder(list.map((i) => i._id)) // send array of ids sorted in new order
						.then((res) => props.refreshlist(res))
						.catch((error) => Swal.fire(swalError(error)))
				} else if (result.isDenied) {
					// insert
					if (a < b) {
						// target comes before source
						list.splice(a, 0, list[b])
						list.splice(b + 1, 1)
						props.refreshlist(list)
						reorder(list.map((i) => i._id)) // send array of ids sorted in new order
							.then((res) => props.refreshlist(res))
							.catch((error) => Swal.fire(swalError(error)))
					} else {
						// target comes after source
						list.splice(a, 0, list[b])
						list.splice(b, 1)
						props.refreshlist(list)
						reorder(list.map((i) => i._id)) // send array of ids sorted in new order
							.then((res) => props.refreshlist(res))
							.catch((error) => Swal.fire(swalError(error)))
					}
				}
			})
	}

	const drag = (ev) => ev.dataTransfer.setData("src", ev.target.id)

	return (
		<div style={styles.imagelist}>
			<div className="gridView">
				{props.imagelist.map((i, index) => {
					return (
						<div key={index} className="replaceDiv" onDragOver={replace_dragover} onDragLeave={replace_dragleave} onDrop={replace_drop}>
							<img id={index} src={IMAGESERVER + i._id + ".png"} alt={"selected"} style={{ width: "15vw" }} draggable={true} onDragStart={drag} />
						</div>
					)
				})}
			</div>
		</div>
	)
}

GridView.propTypes = {
	imagelist: PropTypes.array,
	refreshlist: PropTypes.func,
}

const ImageListing = (props) => {
	const [imageDetailsOpen, setImageDetailsOpen] = useState(false)
	const [image, setImage] = useState(null)
	const [spinnerOpen, setSpinnerOpen] = useState(false)

	const views = [
		<DetailedView imagelist={props.imagelist} refreshlist={(res) => props.refreshlist(res)} />,
		<GridView imagelist={props.imagelist} refreshlist={(res) => props.refreshlist(res)} />,
	]

	const [viewMode, setViewMode] = useState(0)

	const handleFileSelect = (event) => {
		// load image as base64
		const reader = new FileReader()
		reader.readAsDataURL(event.target.files[0])
		reader.onload = () => {
			setImage(reader.result)
			setImageDetailsOpen(true)
		}
	}
	const inputFile = useRef(null)

	const selectFile = () => inputFile.current.click()

	return (
		<div style={styles.mainContainer}>
			<Spinner open={spinnerOpen} />
			<ImageDetails
				image={image}
				open={imageDetailsOpen}
				close={() => setImageDetailsOpen(false)}
				categories={props.imagelist.reduce((arr, curr) => {
					!arr.includes(curr.category) && curr.category !== "" && arr.push(curr.category)
					return arr
				}, [])}
				onChange={(res) => {
					setImageDetailsOpen(false)
					setSpinnerOpen(true)
					saveImage({ ...res, image })
						.then((res) => {
							props.refreshlist(res)
							setSpinnerOpen(false)
						})
						.catch((error) => {
							setSpinnerOpen(false)
							Swal.fire(swalError(error))
						})
				}}
			/>

			<input
				type="file"
				id="file"
				accept="image/png, image/jpeg"
				ref={inputFile}
				style={{ display: "none" }}
				onChange={handleFileSelect}
				onClick={(event) => (event.target.value = "")}
			/>

			<div style={styles.body}>
				<Toolbar onAddNewImage={selectFile} onChangeView={(viewmode) => setViewMode(viewmode)} />

				{views[viewMode]}
			</div>
		</div>
	)
}

const styles = {
	mainContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "100%",
		alignItems: "center",
	},
	body: {
		width: "100%",
		height: "100%",
	},
	imagelist: {
		display: "flex",
		flexDirection: "column",
		marginTop: "5vh",
	},
	listContainer: {
		height: "350px",
		display: "flex",
		flexDirection: "row",
		border: "3px solid silver",
		borderRadius: "10px",
		paddingTop: "10px",
		paddingBottom: "10px",
		margin: "10px",
	},
	imageContainer: {
		height: "100%",
		marginLeft: "20px",
		display: "flex",
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		border: "1px solid silver",
	},
	details: {
		width: "800px",
		display: "flex",
		flex: 4,
		flexDirection: "column",
	},
	buttons: {
		marginLeft: "20px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "flex-end",
		height: "100%",
		width: "100%",
	},
}
ImageListing.propTypes = {
	imagelist: PropTypes.array,
	refreshlist: PropTypes.func,
}

export default ImageListing
