import { PropTypes } from "prop-types"

import { Grow, Modal } from "@material-ui/core"

import { IoCloseOutline } from "react-icons/io5"

import nft from "../../images/nft.png"

const Zoom = (props) => {
	const { description, _id, nftLink, title } = props.data

	return (
		<Modal open={props.open}>
			<div style={styles.overlay}>
				<Grow in={props.open}>
					<div className="zoom">
						<div style={styles.frameHeader}>
							<div style={styles.frameHeaderInnerContainer}>
								<div style={styles.nftLogoContainer} className={nftLink ? "zoomButtons" : ""}>
									{nftLink && <img src={nft} alt="nft" style={styles.nftLogo} onClick={() => window.open(nftLink)} />}
								</div>
								<div style={styles.title}>{title}</div>
								<div className="zoomButtons" style={styles.closeButton} onClick={() => props.close()}>
									<IoCloseOutline />
								</div>
							</div>
						</div>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<img src={props.path + _id + ".png"} alt={"selected"} style={{ width: "50vh" }} />
						</div>

						<div
							style={{
								color: "white",
								padding: "10px",
								textAlign: "center",
								fontSize: "12px",
								width: "90%",
								justifyContent: "center",
								display: "flex",
								alignItems: "center",
							}}
						>
							<div>{description}</div>
						</div>
					</div>
				</Grow>
			</div>
		</Modal>
	)
}

Zoom.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	path: PropTypes.string,
	data: PropTypes.object,
}

const styles = {
	overlay: {
		height: "100vh",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	frameHeader: {
		backgroundColor: "rgba(0,0,0,0.2)",
		width: "100%",
	},
	frameHeaderInnerContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	nftLogoContainer: {
		height: "30px",
		width: "30px",
		borderRadius: "15px",
		marginLeft: "10px",
		marginTop: "10px",
		marginBottom: "10px",
	},
	nftLogo: {
		height: "30px",
		width: "30px",
	},
	title: {
		width: "400px",
		display: "flex",
		justifyContent: "center",
		color: "white",
		letterSpacing: "0.1em",
		padding: "10px",
		textAlign: "center",
	},
	closeButton: {
		color: "white",
		marginRight: "10px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "20px",
		width: "20px",
		borderRadius: "10px",
	},
}

export default Zoom
