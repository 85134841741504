import React from "react"
import ReactDOM from "react-dom"
import { Routes, Route, HashRouter } from "react-router-dom"
import Gallery from "./pages/gallery"
import Panel from "./pages/panel"

import "./index.css"
import App from "./App"

ReactDOM.render(
	<HashRouter>
		<Routes>
			<Route path="/" element={<App />} />
			<Route path="gallery" element={<Gallery />} />
			<Route path="panel" element={<Panel />} />
		</Routes>
	</HashRouter>,
	document.getElementById("root")
)
