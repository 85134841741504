import { useState } from "react"
import { PropTypes } from "prop-types"
import "../App.css"

import { Button, Grow, Modal } from "@material-ui/core"

import signature from "../images/signature.png"

import Intro from "./Intro"
import EasterEgg from "./EasterEgg"

const About = (props) => {
	const pages = [<Intro />, <EasterEgg />]
	const [page, setPage] = useState(0)

	return (
		<Modal open={props.open}>
			<div style={styles.mainContainer}>
				<Grow in={props.open}>
					<div className="aboutBox">
						{pages[page]}
						<div style={styles.footer}>
							<div style={styles.signatureBox} onClick={() => (page === 0 ? setPage(1) : setPage(0))}>
								<img src={signature} alt="signature" style={styles.signature} />
							</div>
							<Button style={styles.button} onClick={() => props.close()}>
								Close
							</Button>
						</div>
					</div>
				</Grow>
			</div>
		</Modal>
	)
}

About.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
}

const styles = {
	mainContainer: {
		height: "100vh",
		width: "100vw",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	footer: {
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		alignItems: "center",
	},
	signatureBox: {
		display: "flex",
		justifyContent: "center",
	},
	signature: {
		height: "10vh",
		// width: "10vh",
	},
	button: {
		width: "200px",
		marginBottom: "10px",
	},
}
export default About
