import { Link } from "react-router-dom"
import { PropTypes } from "prop-types"

import { AiOutlineHome } from "react-icons/ai"

const Header = (props) => {
	return (
		<div style={styles.mainContainer}>
			<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
				<Link to="/">
					<AiOutlineHome className="homeButton" />
				</Link>
				<div style={styles.name}>NATALIE SPRINGFIELD</div>
			</div>
			<div style={{ marginRight: "20px" }}>{props.icon}</div>
		</div>
	)
}

Header.propTypes = {
	icon: PropTypes.node,
}

const styles = {
	mainContainer: {
		width: "100%",
		height: "10vh",
		position: "fixed",
		top: "0px",
		left: "0px",
		backgroundColor: "white",
		zIndex: 10,

		boxShadow: "0px 1px 50px white",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	name: {
		marginLeft: "20px",
		color: "gray",
		letterSpacing: "0.4em",
	},
}
export default Header
