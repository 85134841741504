import { useState } from "react"
import "../App.css"
import { Link } from "react-router-dom"

import { BsPatchQuestion } from "react-icons/bs"

import About from "./About"

import landingPagePic from "../images/landingPagePic.png"

const LandingPage = () => {
	const [aboutOpen, setAboutOpen] = useState(false)
	const [spinnerOpen, setSpinnerOpen] = useState(false)

	return (
		<div style={styles.mainContainer}>
			<About open={aboutOpen} close={() => setAboutOpen(false)} />

			<div style={styles.questionButton}>
				<BsPatchQuestion style={styles.questionIcon} onClick={() => setAboutOpen(true)} />
			</div>
			<div>
				<Link to="/gallery">
					<img className="grow" src={landingPagePic} alt="Landing" style={styles.mainIcon} />
				</Link>
			</div>
			<div style={styles.footer}>All Rights Reserved - Natalie Springfield (2022)</div>
		</div>
	)
}

const styles = {
	mainContainer: {
		backgroundColor: "rgb(183,183,183)",
		height: "100vh",
		width: "100vw",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	questionButton: {
		display: "flex",
		justifyContent: "flex-end",
		marginRight: "20px",
		marginTop: "10px",
	},
	questionIcon: {
		fontSize: "50px",
		color: "gray",
		cursor: "pointer",
	},
	mainIcon: {
		height: "40vh",
		width: "40vh",
	},
	footer: {
		height: "30px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		fontSize: "12px",
	},
}

export default LandingPage
