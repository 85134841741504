import "../App.css"

import { useEffect, useState } from "react"

import nft from "../images/nft.png"

import { getImageList } from "../common/network"
import Header from "../components/Header"
import Zoom from "../components/gallery/Zoom"
import MenuIcon from "../components/gallery/MenuIcon"

import Swal from "sweetalert2"
import { swalError } from "../common/sweetalert"

const path = "https://www.nataliespringfield.com/images/"

const _emptyData = {
	addedOn: "",
	category: "",
	modifiedDate: "",
	description: "",
	img: "",
	nftlink: "",
	title: "",
}

const Gallery = () => {
	const [zoomOpen, setZoomOpen] = useState(false)
	const [imageData, setImageData] = useState(_emptyData)

	const [images, setImages] = useState([])
	const [imageList, setImageList] = useState([])
	const [filter, setFilter] = useState({})

	useEffect(() => {
		getImageList(true) // true gets only the active images
			.then((res) => {
				setImages(res)
				setImageList(res)
			})
			.catch((err) => Swal.fire(swalError(err)))
	}, [])

	useEffect(() => {
		setImageList(
			images.filter((i) => (filter.nft ? i.nftLink !== "" && i : i)).filter((i) => (filter.category === "ALL" ? i : i.category === filter.category))
		)
	}, [filter, images])

	const NoListingFound = () => {
		return <div style={styles.noListingFound}>No listing found</div>
	}

	const handleClick = (i) => {
		setImageData(i)
		setZoomOpen(true)
	}
	return (
		<div style={styles.mainContainer} className="body">
			<Zoom open={zoomOpen} close={() => setZoomOpen(false)} path={path} data={imageData} />

			<Header
				icon={
					<MenuIcon
						images={imageList}
						onFilterChanged={(filter) => setFilter(filter)}
						categories={images.reduce((arr, curr) => {
							!arr.includes(curr.category) && curr.category !== "" && arr.push(curr.category)
							return arr
						}, [])}
					/>
				}
			/>

			<div style={styles.scrollableBody}>
				<div className="gridContainer">
					{imageList.length === 0 ? (
						<NoListingFound />
					) : (
						imageList.map((i) => {
							return (
								<div key={i._id}>
									<div style={{ position: "relative", display: "flex", flexDirection: "column" }} className="grow img">
										<img
											src={path + i._id + ".png"}
											srcSet={path + i._id + ".png"}
											alt={i.title}
											style={styles.imageTile}
											onClick={() => handleClick(i)}
										/>
										{i.nftLink !== "" && (
											<div style={styles.nftLogoContainer} onClick={() => handleClick(i)}>
												<img src={nft} alt="nft logo" style={styles.nftLogo} />
											</div>
										)}
										<div style={{ display: "flex", flexDirection: "column", marginBottom: "10px" }}>
											<div style={{ textAlign: "center" }}>{i.title}</div>
											{i.height > 0 && (
												<div style={{ textAlign: "center", fontSize: "12px", marginTop: "5px" }}>
													{i.height}
													{i.unitOfMeasurement} (h) x{i.width}
													{i.unitOfMeasurement} (w)
												</div>
											)}
										</div>
									</div>
								</div>
							)
						})
					)}
				</div>
			</div>
		</div>
	)
}

const styles = {
	mainContainer: {
		display: "flex",
		flexDirection: "column",
		paddingTop: "120px",
	},
	noListingFound: {
		width: "100vw",
		height: "80vh",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	scrollableBody: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		marginTop: "100px",
		marginBottom: "100px",
	},
	imageTile: {
		height: "300px",
		objectFit: "contain",
		border: "5px solid ghostwhite",
		borderRadius: "15px",
	},
	nftLogoContainer: {
		top: "20px",
		left: "20px",
		position: "absolute",
	},
	nftLogo: {
		height: "20px",
		width: "20px",
	},
}

export default Gallery
