import { useState } from "react"
import { PropTypes } from "prop-types"

import { AiOutlineMenu, AiOutlineInstagram } from "react-icons/ai"
import { VscUngroupByRefType, VscChevronRight } from "react-icons/vsc"

import nft from "../../images/nft.png"

import { Divider, Drawer, IconButton, List, ListItem, ListItemText, MenuItem, MuiThemeProvider, TextField } from "@material-ui/core"

import { THEME } from "../../App"

const igLink = "https://www.instagram.com/artbynutterbutter/"

const MenuIcon = (props) => {
	const [showMenu, setShowMenu] = useState(false)
	const [category, setCategory] = useState("ALL")
	const [onlyNFT, setOnlyNFT] = useState(false)

	const Menu = (props) => {
		return (
			<MuiThemeProvider theme={THEME}>
				<div style={styles.mainContainer}>
					<div style={{ color: "gray", margin: "10px", display: "flex", flexDirection: "row", alignItems: "center" }}>
						<IconButton style={{ marginRight: "10px" }}>
							<VscChevronRight />
						</IconButton>
					</div>

					<Divider />
					<div style={{ paddingLeft: "30px", paddingTop: "20px", letterSpacing: "0.2em" }}>Filters</div>
					<List>
						<ListItem alignItems="flex-start" button>
							<div style={{ display: "flex", flexDirection: "row" }}>
								<div style={{ paddingTop: "10px", ...styles.menuIcon }}>
									<VscUngroupByRefType style={{ color: "gray", fontSize: "20px" }} />
								</div>

								<TextField
									select
									style={styles.select}
									value={category}
									onChange={(e) => {
										props.onFilterChanged({ category: e.target.value, nft: onlyNFT })
										setCategory(e.target.value)
									}}
								>
									<MenuItem value="ALL">All</MenuItem>
									{props.categories.map((i) => (
										<MenuItem key={i} value={i}>
											{i}
										</MenuItem>
									))}
								</TextField>
							</div>
						</ListItem>
						<ListItem
							button
							onClick={() => {
								props.onFilterChanged({ category, nft: !onlyNFT })
								setOnlyNFT(!onlyNFT)
							}}
						>
							<div style={styles.menuIcon}>
								<img src={nft} alt="nft" style={styles.nftIcon} />
							</div>
							<ListItemText>{onlyNFT ? "Show All" : "Only show NFTs"}</ListItemText>
						</ListItem>
					</List>
					<Divider />
					<div style={{ marginTop: "20px" }}>
						<List>
							<ListItem button onClick={() => window.open(igLink)}>
								<div style={{ ...styles.menuIcon, marginLeft: "0px" }}>
									<AiOutlineInstagram />
								</div>
								<ListItemText>Instagram</ListItemText>
							</ListItem>
						</List>
					</div>
				</div>
			</MuiThemeProvider>
		)
	}

	Menu.propTypes = {
		close: PropTypes.func,
		categories: PropTypes.array,
		onFilterChanged: PropTypes.func,
	}

	return (
		<div style={{ cursor: "pointer" }} onClick={() => setShowMenu(!showMenu)}>
			<Drawer anchor="right" open={showMenu} variant="persistent">
				<Menu close={() => setShowMenu(false)} categories={props.categories} onFilterChanged={props.onFilterChanged} />
			</Drawer>
			<AiOutlineMenu />
		</div>
	)
}

MenuIcon.propTypes = {
	images: PropTypes.array,
	categories: PropTypes.array,
	setImages: PropTypes.func,
	onFilterChanged: PropTypes.func,
}

const styles = {
	mainContainer: {
		display: "flex",
		flexDirection: "column",
		padding: "10px",
		width: "100%", //
	},
	menuIcon: {
		fontSize: "30px",
		color: "gray",
		marginRight: "30px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginLeft: "5px",
	},
	select: {
		marginTop: "20px",
		marginBottom: "10px",
		width: "200px",
	},
	nftIcon: {
		height: "20px",
		width: "20px", //
	},
}
export default MenuIcon
