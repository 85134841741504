import { useState } from "react"
import { PropTypes } from "prop-types"

import { Button, TextField } from "@material-ui/core"

import { auth } from "../../common/network"

import Swal from "sweetalert2"
import { swalError } from "../../common/sweetalert"

const Login = (props) => {
	const [username, setUsername] = useState("nutterbutter")
	const [password, setPassword] = useState("zulkarnain")

	return (
		<div style={styles.mainContainer}>
			<TextField
				autoFocus
				label="username"
				style={{ width: "300px", marginBottom: "15px" }}
				InputLabelProps={{ shrink: true }}
				onChange={(e) => setUsername(e.target.value)}
			/>
			<TextField
				label="password"
				style={{ width: "300px", marginBottom: "35px" }}
				InputLabelProps={{ shrink: true }}
				onChange={(e) => setPassword(e.target.value)}
			/>
			<Button
				style={{ width: "150px" }}
				variant="outlined"
				onClick={() => {
					if (username.length < 5 || password.length < 8) Swal.fire(swalError("Invalid Username/Password"))
					else
						auth(username, password)
							.then((res) => props.loginPassed(res))
							.catch((error) => props.loginFailed(error))
				}}
			>
				Login
			</Button>
		</div>
	)
}

Login.propTypes = {
	loginPassed: PropTypes.func,
	loginFailed: PropTypes.func,
}

const styles = {
	mainContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "100%",
		justifyContent: "center",
		alignItems: "center",
	},
}

export default Login
