import { useState } from "react"

import Swal from "sweetalert2"
import { swalError } from "../common/sweetalert"

import Header from "../components/Header"
import Login from "../components/panel/Login"
import ImageListing from "../components/panel/ImageListing"

const Panel = () => {
	const [imagelist, setImageList] = useState([]) // populated on login, refreshed in ImageListing

	const pages = [
		<Login
			loginPassed={(res) => {
				setImageList(res)
				setActivePage(1)
			}}
			loginFailed={(error) => Swal.fire(swalError(error))}
		/>,
		<ImageListing imagelist={imagelist} refreshlist={(res) => setImageList(res)} />,
	]
	const [activePage, setActivePage] = useState(0)

	return (
		<div style={styles.mainContainer}>
			<Header />
			<div style={styles.body}>{pages[activePage]}</div>
		</div>
	)
}

const styles = {
	mainContainer: {
		display: "flex",
		flexDirection: "column",
		paddingTop: "100px",
		position: "relative",
		height: "100%",
		width: "100%",
	},
	body: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		height: "90vh", // compensate for header, needed for Login page alignments
		width: "100vw",
	},
	content: {
		height: "100%",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
}
export default Panel
