import { useEffect, useState } from "react"
import { PropTypes } from "prop-types"

import { Button, Modal, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"

import Swal from "sweetalert2"
import { swalError } from "../../common/sweetalert"

import { THEME } from "../../App"
import { MuiThemeProvider } from "@material-ui/core"

const ImageDetails = (props) => {
	const [title, setTitle] = useState("")
	const [category, setCategory] = useState("")
	const [nftLink, setNFTLink] = useState("")
	const [description, setDescription] = useState("")

	useEffect(() => {
		setTitle("")
		setCategory("")
		setNFTLink("")
		setDescription("")
	}, [props.open])

	return (
		<Modal open={props.open}>
			<div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" , zIndex: 20000}}>
				<MuiThemeProvider theme={THEME}>
					<div
						style={{
							height: "50%",
							width: "50%",
							backgroundColor: "white",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<div style={{ display: "flex", flexDirection: "row", height: "90%", width: "100%" }}>
							<div style={{ width: "40%", display: "flex", justifyContent: "center", alignItems: "center" }}>
								<img src={props.image} alt="selected" style={{ width: "80%", display: props.image ? "flex" : "none" }} />
							</div>
							<div style={{ width: "60%", display: "flex", justifyContent: "center", alignItems: "center" }}>
								<div style={{ height: "80%", width: "90%", display: "flex", flexDirection: "column" }}>
									<TextField
										value={title}
										label="title"
										InputLabelProps={{ shrink: true }}
										style={{ marginBottom: "20px" }}
										onChange={(e) => setTitle(e.target.value)}
									/>

									<Autocomplete
										freeSolo
										disableClearable
										options={props.categories}
										onChange={(e, value) => setCategory(value)}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Category"
												value={category}
												onChange={(e) => setCategory(e.target.value)}
												InputLabelProps={{ shrink: true }}
												style={{ marginBottom: "20px" }}
												InputProps={{
													...params.InputProps,
													// 	type: "search",
												}}
											/>
										)}
									/>

									{/* <TextField
									select
									value={category}
									label="Category"
									InputLabelProps={{ shrink: true }}
									style={{ marginBottom: "20px" }}
									onChange={(e) => setCategory(e.target.value)}
								/> */}

									<TextField
										value={nftLink}
										label="NFT Link"
										InputLabelProps={{ shrink: true }}
										style={{ marginBottom: "20px" }}
										onChange={(e) => setNFTLink(e.target.value)}
									/>

									<div style={{ padding: "10px", height: "100%", border: "1px solid silver", borderRadius: "5px" }}>
										<TextField
											value={description}
											multiline
											maxRows={4}
											label="Description"
											InputLabelProps={{ shrink: true }}
											InputProps={{ disableUnderline: true }}
											style={{ marginBottom: "20px", height: "100%", width: "100%" }}
											onChange={(e) => setDescription(e.target.value)}
										/>
									</div>
								</div>
							</div>
						</div>
						<div
							style={{
								display: "flex",
								width: "100%",
								flexDirection: "row",
								justifyContent: "flex-end",
								marginRight: "20px",
							}}
						>
							<Button style={{ width: "90px" }} onClick={() => props.close()}>
								Cancel
							</Button>
							<Button
								style={{ width: "90px", marginRight: "5px" }}
								onClick={() => {
									if (title.trim() === "") Swal.fire(swalError("Please enter a title"))
									else
										props.onChange({
											title,
											category,
											nftLink,
											description,
										})
								}}
							>
								Save
							</Button>
						</div>
					</div>
				</MuiThemeProvider>
			</div>
		</Modal>
	)
}

ImageDetails.propTypes = {
	open: PropTypes.bool,
	onChange: PropTypes.func,
	close: PropTypes.func,
	image: PropTypes.node,
	categories: PropTypes.array,
}

export default ImageDetails
