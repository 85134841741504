import { PropTypes } from "prop-types"

import { Modal } from "@material-ui/core"

import { HashLoader } from "react-spinners"

const Spinner = (props) => {
	return (
		<Modal open={props.open}>
			<div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" , zIndex: 20000}} onClick={() => props.close()}>
				<HashLoader color={"blue"} loading={props.open} size={150} />
			</div>
		</Modal>
	)
}

Spinner.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
}

export default Spinner
