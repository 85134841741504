import "../App.css"

import nft from "../images/nft.png"

const Intro = () => {
	return (
		<div style={styles.mainContainer}>
			<img src={nft} alt="NFT" style={styles.logo} />
			<div style={{ width: "80%", marginTop: "20px" }}>
				<div style={{ textAlign: "center" }}>Some of the works listed here can be purchased as a Non-Fungible Tokens</div>
				<div style={{ marginTop: "10px", textAlign: "center" }}>
					If you are interested, click on the NFT logo in each frame in the gallery, and you would be redirected to purchase it at Opensea.com.
					<p>Works with NFT available has a logo displayed in the gallery</p>
				</div>
			</div>
		</div>
	)
}

const styles = {
	mainContainer: {
		height: "70%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center", //
	},
	logo: {
		height: "10vh",
		width: "10vh", //
	},
}
export default Intro
